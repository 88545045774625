import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import ContentPage from "../../../layouts/contentPage"

import Seo from "../../../components/seo"

import ProductHero from "../../../components/Product/productHero"
import H1 from "../../../components/h1"
import ButtonColor from "../../../components/buttonColor"
import ProductInfo from "../../../components/Product/productInfo"
import ProductPackshot from "../../../components/Product/productPackshot"
import ProductPackshotBubbles from "../../../components/Product/productPackshotBubbles"
import BubbleBg from "../../../components/Product/bubbleBg"
import ProductSidebar from "../../../components/Product/productSidebar"
import More from "../../../components/more"
import Tabs from "../../../components/Tabs/tabs"
import ProductDescription from "../../../components/Product/productDescription"
import Acid from "../../../images/media/products/acid/acid.svg";
import Ingredients from "../../../components/Product/ingredients"
import Ingredient from "../../../components/Product/ingredient"
import Effectivness from "../../../components/Product/effectivness"
import EffectivnessCard from "../../../components/Product/effectivnessCard"
import ActiveIngredients from "../../../components/Product/activeIngredients"
import ActiveIngredient from "../../../components/Product/activeIngredient"
import DescriptionPackshot from "../../../components/Product/descriptionPackshot"
import Container from "/src/components/Product/container"






const Product = () => (
    <Layout lang={"EN"}>
      <Seo title="Anti-aging acid peeling - Acid Peel" lang={"EN"}/>
        <ProductHero>

            {/* Info */}
            <div data-column>
                <ProductInfo color="blue">
                    <H1>Anti-aging acid peeling</H1>
                    <p>Wrinkle control and skin renewal </p>
                    <ProductPackshot mobile>
                        <StaticImage
                        src="../../images/media/products/acid/products/przeciwstarzeniowy-peeling.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="Anti-aging acid peeling"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>

                    <ul>
                        <li>Plumps at the skin at cell level. </li>
                        <li>Minimises deep wrinkles. </li>
                        <li>Refines and improves skin texture. </li>
                        <li>Oxygenates and energizes skin cells for amazing anti-ageing effect.</li>
                    </ul>
                    <Container>
                        <ButtonColor as="a" target="_blank" href="https://www.hebe.pl/oxygenetic-przeciwstarzeniowy-peeling-kwasowy-do-twarzy-50-ml-000000000000407505.html" color="blue">Buy in Hebe</ButtonColor>
                        <ButtonColor as="a" target="_blank" href="https://www.zikodermo.pl/oxygenetic-acid-peel-przeciwstarzeniowy-peeling-kwasowy-50-ml.html" color="blue">Buy in Ziko Dermo</ButtonColor>
                        <ButtonColor as="a" target="_blank" href="https://gemini.pl/oxygenetic-acid-peel-aha-pha-przeciwstarzeniowy-peeling-kwasowy-50-ml-0107783" color="blue">Buy in Gemini</ButtonColor>
                        <ButtonColor as="a" target="_blank" href="https://www.doz.pl/apteka/p176921-Oxygenetic_Acid_Peel_AHAPHA_przeciwstarzeniowy_peeling_kwasowy_50_ml" color="blue">Buy in DOZ</ButtonColor>
                    </Container>
                </ProductInfo>
            </div>

            {/* Packshot */}
            <div data-column>
                <ProductPackshot>
                        <StaticImage
                        src="../../images/media/products/acid/products/przeciwstarzeniowy-peeling.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="przeciwstarzeniowy peeling"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                <More url="#opis-produktu" color="blue" text="more"/>
            </div>

            {/* Category Products */}
            <div data-column>
                <ProductSidebar lang={"EN"}>
                    <Link to="/en/products/renewing-peeling">
                        <StaticImage
                            src="../../images/media/products/acid/products/odnawiajacy-peeling.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="Renewing acid peeling"
                        />
                    </Link>
                </ProductSidebar>
            </div>
            {/* <BubbleBg/> */}
        </ProductHero> 
        <section id="opis-produktu">

            {/* TABS */}
            <Tabs color="blue">

                {/* OPIS */}
                <div label="Description" className="tab-list-active">
                <ProductDescription color="blue">
                    <div className="descriptionRow">
                        <div className="column">
                            <div className="text--left">

                                <p>
                                    <strong>OXYGENETIC ACID PEEL AHA + PHA Anti-aging acid peeling</strong>
                                </p>
                                <p>
                                   This is a wonderful peel to penetrate into deeper layers of the skin, stimulate collagen and elastin synthesis so that you can enjoy noticeably  firmer facial contour  and firmer and more supple skin.
                                </p>
                                <p>
                                Fine lines get visibly smoothed and rejuvenated. This peel, like a mortar, induces natural  ceramide synthesis to repair barrier function and lock in moisture for the skin to be rehydrated and TEWL prevented as ceramides are responsible for a healthy skin and ceramides hold the skin together and form a protective layer.
                                </p>
                                <p>
                                Also, minimises production of melatonin to brighten and fade blemishes and dark marks. It acts on metabolites in the skin to refine, rejuvenate and revive skin for young  look and radiant complexion.
                                </p>
                                <p>
                                This is a perfect peel to restore plumpness, elasticity, fight wrinkles, even tone and hydrate the skin. 

                                </p>
                            </div>
                        </div>
                        <div className="column">
                            <DescriptionPackshot>
                                <StaticImage
                                src="../../images/media/products/acid/products/przeciwstarzeniowy-peeling_single.png"
                                loading="lazy"
                                width={534}
                                quality={95}
                                placeholder="none"
                                formats={["auto", "webp", "avif"]}
                                alt="przeciwstarzeniowy peeling"
                                />
                            </DescriptionPackshot>
                        </div>
                        <div className="column">
                            <div className="text--right">
                                <p><strong>How to use: </strong></p>
                                <p>
                                    Apply a thin layer of peel to your face, neck and decollette. Leave it on overnight and wash off in the morning. Use for 3 weeks as a treatment. In the first week apply every second day. Then use daily until the end of week three. For best long-term effect continue using 1-2 times per week. Always use  a sunscreen or sunblocker  with this peel and avoid exposure to UV radiation. Avoid direct contact with eyes.
                                </p>
                                <br/>
                                <p><strong>Volume:</strong></p>
                                <p>50ml</p>
                                <span className="acid-peel">
                                    <Acid/>
                                </span>
                            </div>
                        </div>
                    </div>        
                </ProductDescription>
                </div>

                {/* BAZA */}
                <div label="Base">
                <Ingredients>
                    <Ingredient name="Retinol with adenosine" desc="This advanced formulation of retinol and adeosine blend  visibly transforms skin to help skin look younger. Promotes collagen and elastin synthesis to reverse the signs of photoaging, improving fine lines and wrinkles while simultanesouly repairing UV-induced free radical damage for a visibly lifted, ageless and radiant look." />
                    <Ingredient name="Natural plant AHA acid complex" desc="this blend of 5 alpha-hydroxy acids ( lemon of potent repair processes in the skin) . Exfoliates top layers of the skin to stimulate new cell production for smooth skin effect.  " />
                    <Ingredient name="Blend of oxygentaing minerals" desc="Minerals are the component which binds oxygen molecules which are absorbed by the skin and stimulates metabolic processes in the skin so that more energy is released and skin cells start to function properly for healthy and radiant look." />
                    <Ingredient name="Gluconactone" desc="is a polyhydroxy acid (PHA). It is strong , but it contains skin-calming antioxidant combination to help balance things out.  Delivers anti-aging compounds to fight skin aging and protect against ultraviolet radiation.  Mineral complex is a wonderful blend oxygenetating skin cells and metabollising agent so that skin cells get the energy to resurface and refine and  to help enjoy healthy and radiant skin look and enhance glow. " />
                </Ingredients>
                </div>

                {/* SKŁADNIKI AKTYWNE */}
                <div label="Active ingredients">
                <ActiveIngredients>
                    <ActiveIngredient name="Hyaluronic acid" desc="a natural lubricant and a powerful humectant (substance whthat binds to water molecules to retain moisture in the skin and joints for a youthful-looking, plump appearance and structure) reduces the effects og aging by increasing skin elasticity. Hyaluronic acid a staplein your skincare routine." />
                    <ActiveIngredient name="Panthenol" desc="Provitamin B5, which penetrates into the deeper layers of the skin, moisturizes it and soothes irritations. Additionally, it reduces transepidermal water loss (TEWL) and restores hydrolipid balance." />
                </ActiveIngredients>
                </div>

                {/* SKUTECZNOŚĆ */}
                <div label="Effectivness">
                <Effectivness>
                    <EffectivnessCard color="blue" percentage="80" desc="badanych potwierdza, że produkt nawilża i odżywia skórę"/>
                    <EffectivnessCard color="blue" percentage="75" desc="badanych potwierdza, że po zastosowaniu skóra staje się jędrna i elastyczna"/>
                    <EffectivnessCard color="blue" percentage="70" desc="badanych potwierdza, że produkt zmniejsza widoczność przebarwień"/>
                </Effectivness>
                </div>
            </Tabs>
        </section>
    </Layout>
)

export default Product
